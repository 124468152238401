import "./src/components/page404/style404.scss"
import "./src/styles/_variables.scss"
import "./src/components/sharedComponents/headerAndFooter/header/header.scss"
import "./src/components/sharedComponents/headerAndFooter/footer/footer.scss"
import "./src/components/pageHome/intro/intro.scss"
import "./src/components/pageHome/support/support.scss"
import "./src/components/sharedComponents/accordionRight/accordionRight.scss"
import "./src/components/sharedComponents/accordionLeft/accordionLeft.scss"
import "./src/components/pageHome/operations/operations.scss"
import "./src/components/sharedComponents/bannerCTA/bannerCTA.scss"
import "./src/components/sharedComponents/bannerContact/bannerContact.scss"
import "./src/components/sharedComponents/articleBlog/articleBlog.scss"
import "./src/components/sharedComponents/medios/medios.scss"
import "./src/components/pageHome/products/products.scss"
import "./src/components/sharedComponents/testimonies/testimonies.scss"
import "./src/components/sharedComponents/customersLogos/customersLogos.scss"
import "./src/components/sharedComponents/bannerCookies/bannerCookies.scss"
import "./src/components/pageTicker/intro/intro.scss"
import "./src/components/pageTicker/bannerLogin/bannerLogin.scss"
import "./src/components/pageTicker/integrations/integrations.scss"
import "./src/components/pageTicker/experience/experience.scss"
import "./src/components/pageChannels/pageWhatsapp/experience/experience.scss"
import "./src/components/pageScore/bannerNegro/bannerNegro.scss"
import "./src/components/pageScore/industries/industries.scss"
import "./src/components/pageScore/bannerEbook/bannerEbook.scss"
import "./src/components/pageScore/intro/intro.scss"
import "./src/components/pageChannels/pageWhatsapp/intro/intro.scss"
import "./src/components/pageChannels/pageWhatsapp/industries/industries.scss"
import "./src/components/sharedComponents/bannerVideo/bannerVideo.scss"
import "./src/components/sharedComponents/navbarVertical/navbarVertical.scss"
import "./src/components/pageCasosdeUso/components/intro/intro.scss"
import "./src/components/pageCasosdeUso/pageAtencion/experience/experience.scss"
import "./src/components/pageCasosdeUso/pageMarketing/experience/experience.scss"
import "./src/components/pageChannels/pageWebchat/experience/experience.scss"
import "./src/components/pageChannels/pageWebchat/conversions/conversions.scss"
import "./src/components/pageCasosdeUso/pageCobros/experience/experience.scss"
import "./src/components/pageIndustrias/pageEcommerce/experience/experience.scss"
import "./src/components/pageIndustrias/pageContactCenter/experience/experience.scss"
import "./src/components/pageChannels/pageChatbots/experience/experience.scss"
import "./src/components/pageChannels/pageInstagram/experience/experience.scss"
import "./src/components/pageChannels/pageChatbots/industries/industries.scss"
import "./src/components/pageCasosdeUso/components/industries/industries.scss"
import "./src/components/pageFunctionalities/pageVoiceApi/experience/experience.scss"
import "./src/components/pageHablemos/styles.scss"
import "./src/components/pageThanks/pageThanksGeneral/components/start.scss"
import "./src/components/pageThanks/pageThanksGeneral/components/useCases.scss"
import "./src/components/pageTicker/pagePrice/components/comparePrice/comparePrice.scss"
import "./src/components/pageTicker/pagePrice/components/question/question.scss"
import "./src/components/pageScore/pagePrice/components/comparePrice.scss"

import "./src/components/pagePartners/components/benefit/benefit.scss"
import "./src/components/pagePartners/components/levels/levels.scss"
import "./src/components/pagePartners/components/start/start.scss"
import "./src/components/pagePartners/components/product/product.scss"
import "./src/components/pagePartners/components/questions/questions.scss"
import "./src/components/pagePartners/components/whoCan/whoCan.scss"
import  "./src/components/pagePartners/pageForm/form.scss"
import "./src/components/pageRecursos/components/cardRecursos.scss"


import "./src/components/pageEbook/components/intro/intro.scss"
import "./src/components/pageEbook/components/description/description.scss"
import "./src/components/pageEbook/components/slider/slider.scss"


import "./src/components/pageGuide/components/intro/intro.scss"
import "./src/components/pageGuide/components/description/description.scss"
import "./src/components/pageGuide/components/slider/slider.scss"

import "./src/components/pageThanks/pageThanksTicker/components/useCases/useCases.scss"
import "./src/components/pageThanks/pageThanksTicker/components/silderPricing/silderPricing.scss"
import "./src/components/pageThanks/pageThanksScore/components/solutions/solutions.scss"
import "./src/components/pageThanks/pageThanksScore/components/silderPricing/silderPricing.scss"

import "./src/components/pageFunctionalities/pageWhatsappPrecio/intro/intro.scss"
import "./src/components/pageFunctionalities/pageWhatsappPrecio/question/question.scss"
import "./src/components/pageFunctionalities/pageWhatsappPrecio/escaner/escaner.scss"
import "./src/components/pageFunctionalities/pageWhatsappPrecio/conversacion/conversacion.scss"
import "./src/components/pageFunctionalities/pageWhatsappPrecio/calculador/calculador.scss"

import "./src/components/pageSoporte/components/start/start.scss"
import "./src/components/pageSoporte/components/becauseWe/becauseWe.scss"
import "./src/components/pageSoporte/components/testimonios/testimonios.scss"

import "./src/components/pageNosotros/styleNosotros/aboutUs.scss"
import "./src/components/pageNosotros/styleNosotros/adn.scss"
import "./src/components/pageNosotros/styleNosotros/galery.scss"
import "./src/components/pageNosotros/styleNosotros/inspired.scss"
import "./src/components/pageNosotros/styleNosotros/intro.scss"
import "./src/components/pageNosotros/styleNosotros/navsupport.scss"
import "./src/components/pageNosotros/styleNosotros/recognition.scss"
import "./src/components/pageNosotros/styleNosotros/sentence.scss"
import "./src/components/pageNosotros/styleNosotros/teams.scss"
import "./src/components/pageNosotros/styleNosotros/years.scss"

import "./src/components/pagePostulacion/style/start.scss"
import "./src/components/pageAgile/style/becauseWe.scss"
import "./src/components/pageAgile/style/intro.scss"
import "./src/components/pageAgile/style/modules.scss"
import "./src/components/pageAgile/style/sentence.scss"

import "./src/components/pageCasosdeExito/style/_cardsPageCasos.scss"
import "./src/components/pageCasosdeExito/style/_descriptionCustomer.scss"
import "./src/components/pageCasosdeExito/style/_introCustomer.scss"
import "./src/components/pageCasosdeExito/style/_modulesCustomer.scss"

import "./src/components/pageLegal/style/ctaCookies.scss"
import "./src/components/pageLegal/style/description.scss"
import "./src/components/pagePlatcon/intro/intro.scss"

import "./src/components/pagePlatcon/operations/operations.scss"

import "./src/components/pageScore/bannerLogin/popoverLogin.scss"
import "./src/components/pageScore/bannerLogin/bannerLogin.scss"
import "./src/components/pageScore/pageContactCenterOmnicanal/style/cardCases.scss"
import "./src/components/pageScore/pageContactCenterOmnicanal/style/intro.scss"
import "./src/components/pageScore/pageContactCenterOmnicanal/components/silderPricing/silderPricing.scss"
import "./src/components/pageTicker/pageSolucionOmnicanal/components/start.scss"
import "./src/components/pageCasosdeUso/pageAlternativaMarketing/styles/marketing.scss"
import "./src/components/pageFunctionalities/pageAlternativaWhatsapp/styles.scss"
import "./src/components/pageIndustrias/components/intro/intro.scss"
import "./src/components/pageFunctionalities/pageWhatsappContact/components/form.scss"

import "./src/components/pageSoporte/components/compare/compare.scss"